import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'wl-plan-duration',
  templateUrl: './plan-duration.component.html',
  styleUrls: ['./plan-duration.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanDurationComponent {
  @Input() isInsurance: boolean | null = false;
  @Input() isDirectDebit = false;
  @Input() isRenewal = false;
}
